import { Controller } from "stimulus";

export default class extends Controller {
  // connect() {
  //   console.log('connect!')
  // }

  reset() {
    this.element.reset();
  }
}
